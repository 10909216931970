<template>
  <div class="draw">
    <div class="top">
      <img src="https://konewx.obs.cn-south-1.myhuaweicloud.com/drawOK.png" class="draw_ok"></img>
      <div class="draw_text">领取权益成功</div>
    </div>
    <div class="bnt">
      <div class="qr_box">
        <div class="qr_text">专属客服</div>
        <div class="qr_background">
          <img :src="url" class="qr_img"></img>
        </div>
        <div class="draw_kf">长按添加专属客服</div>
        <div class="draw_zs">7*24小时专属客服服务</div>
      </div>
    </div>
    
  </div>
</template>
<script>
import qs from 'qs'
import { Toast } from 'vant'

export default {
  name: 'KVip-no',
  data() {
    return {
      url:'',
      buyflag: false,
      show: false,
      mac: '',
      start_time: '',
      end_time: '',
      list: [{
        title: '点歌特权',
        R_list: [{
          name: '百万歌曲免费唱',
          id: 1
        },
        {
          name: '无唤醒语音',
          id: 2
        }, {
          name: '极速下载',
          id: 3
        }, {
          name: '录音收藏',
          id: 4
        },
        ]   
      }, {
        title: '电影特权',
        R_list: [{
          name: '电影年会员8折',
          id: 5
        },
        {
          name: '超高清画质',
          id: 6
        }
        ]
      },],
    }
  },
  created() {
    let _this = this
    this.mac = this.getQueryVariable('mac')
    this.is_support_film = this.getQueryVariable('is_support_film')
    this.url = this.$route.query.url
  },

  methods: {
    weixinClosePage() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', weixin_ClosePage, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', weixin_ClosePage);
          document.attachEvent('onWeixinJSBridgeReady', weixin_ClosePage);
        }
      } else {
        this.weixin_ClosePage();
      }
    },

    weixin_ClosePage() {
      WeixinJSBridge.call('closeWindow');
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    }
    ,
    close: function () {

    }
    ,
    showpop: function () {
      this.show = true
    }
    ,
    queryitem: function (item, index) {
      item.check = !item.check
    }
    ,
    getLocalTime(nS) {
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    }
    ,
    confirm: function () {
      let _this = this
      _this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let scene = []
      _this.list.map(item => {
        if (item.check == true) {
          scene.push(item.id)
        }
      })
      if (scene.length > 0) {
        this.axios.post('/api/activate/newActiveM210Mac', {
          mac: _this.mac || '00:05:29:CE:01:6E',
          use_scene: scene,
        }).then(data => {
          if (data.code == 200) {
            _this.$toast.success('成功开通VIP');
            _this.start_time = _this.getLocalTime(data.data.active_time).substring(0, 10)
            _this.end_time = _this.getLocalTime(data.data.expire_time).substring(0, 10)
            console.log(_this.start_time)
            console.log(_this.end_time)
            _this.show = false
            _this.buyflag = true
          } else {
            _this.$toast.fail(data.msg);
            _this.show = false
          }
        }).catch(err => {
          console.log(err)
          _this.$toast.fail(data.msg);
        })
      } else {
        _this.$toast.fail('请选择场景');
      }

    }
  }
}
</script>
<style scoped>
.draw {
  width: 100%;
  height: 100vh;
  background-color: rgba(242, 245, 248, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.top {
  width: 100%;
  height: 152px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.draw_ok {
  width: 60px;
  height: 60px;
  margin-top: 30px;
}
.draw_text {
  margin-top: 10px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  line-height: 20px;
}
.bnt{
  height: calc(100% - 152px);
}
.qr_box {
  width: 300px;
  height: 367px;
  background: linear-gradient(180deg, #ff4bf0 0%, #9449ff 100%);
  border-radius: 20px;
  margin-top: 20px;
  display: flex;
  padding-top: 14px;
  flex-direction: column;
  align-items: center;
}
.qr_text {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
}
.qr_background {
  margin-top: 13px;
  width: 226px;
  height: 226px;
  background: #ffffff;
  border-radius: 10px;
}
.qr_img {
  width: 100%;
  height: 100%;
}
.draw_kf {
  margin-top: 21px;
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
}
.draw_zs {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  line-height: 20px;
}
</style>